import Axios from "axios";
import toast from "react-hot-toast";
import { ServiceUrl } from "../utils/serviceUrl";
import callApi from "../utils/callApi";
import { jsonToQuery } from "../utils";

export const listHrGrades = async (status = false) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.HR.LIST_GRADES}`;
    if (status) url = url + "?status=" + status;
    Axios.get(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          toast.error(data.message || "Error");
          resolve([]);
        }
        resolve(data.data);
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};

export const listHrBreakupHeads = async (status = false) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.HR.LIST_BREAKUP_HEADS}`;
    if (status) url = url + "?status=" + status;
    Axios.get(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          toast.error(data.message || "Error");
          resolve([]);
        }
        resolve(data.data);
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};

export const liststGateWays = async (status = false, leaveDate = false) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.SETTINGS.LIST_GATEWAY_MASTER}`;
    if (status) url = url + "?status=" + status;
    Axios.get(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          //toast.error(data.message || 'Error');
          resolve([]);
        }
        resolve(data.data);
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};

export const liststCertType = async (status = false, leaveDate = false) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.CERTIFICATE.LIST_CERTIFCATE_TYPE}`;
    if (status) url = url + "?status=" + status;
    Axios.post(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          //toast.error(data.message || 'Error');
          resolve([]);
        }
        resolve(data.data);
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};

export const liststnocType = async (
  is_supperior_approve = false,
  leaveDate = false
) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.CERTIFICATE.LIST_NOC_TYPE}`;
    if (is_supperior_approve)
      url = url + "?is_supperior_approve=" + is_supperior_approve;
    Axios.post(url)
      .then(function ({ data }) {
        if (data.is_supperior_approve != "1") {
          //toast.error(data.message || 'Error');
          resolve([]);
        }
        resolve(data.data);
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};

export const getCategoryList = async (status = false, leaveDate = false) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.ASSETS.LIST_CATEGORY}`;
    if (status) url = url + "?status=" + status;
    Axios.post(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          //toast.error(data.message || 'Error');
          resolve([]);
        }
        resolve(data.data);
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};

export const listSupplierlist = async (status = false, getValues = false) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.FINANCE.LIST_SUPPLIERS}`;
    if (status) url = url + "?status=" + status;
    if (getValues) url = url + "&" + getValues;
    Axios.get(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          toast.error(data.message || "Error");
          resolve([]);
        }
        resolve(data.data);
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};

export const getFinanceCategoryList = async (
  status = false,
  getValues = false
) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.FINANCE.LIST_CATEGORY_TRANSACTION}`;
    if (status) url = url + "?status=" + status;
    if (getValues) url = url + "&" + getValues;
    Axios.post(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          toast.error(data.message || "Error");
          resolve([]);
        }
        resolve(data.data);
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};

export const getLocationList = async (status = false, leaveDate = false) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.ASSETS.LIST_ASSET_LOCATION}`;
    if (status) url = url + "?status=" + status;
    Axios.post(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          //toast.error(data.message || 'Error');
          resolve([]);
        }
        resolve(data.data);
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};
export const getSupplierList = async (status = false, leaveDate = false) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.ASSETS.LIST_SUPPLIER_PURCHASE}`;
    if (status) url = url + "?status=" + status;
    Axios.post(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          //toast.error(data.message || 'Error');
          resolve([]);
        }
        resolve(data.data);
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};

export const listCourses = async (status = false, leaveDate = false) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.ACADEMIC.LIST_COURSES}`;
    if (status) url = url + "?status=" + status;
    Axios.get(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          //toast.error(data.message || 'Error');
          resolve([]);
        }
        resolve(data.data);
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};

export const listBioMetricDevices = async (status = false) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.HR.LIST_BIOMETRIC_DEVICES}`;
    if (status) url = url + "?status=" + status;
    Axios.get(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          toast.error(data.message || "Error");
          resolve([]);
        }
        resolve(data.data);
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};

export const listHolidays = async (status = false, monthyear = false) => {
  return new Promise((resolve, reject) => {
    const d = new Date();
    let year = d.getFullYear();
    let url = `${ServiceUrl.HR.LIST_HOLIDAYS}`;
    if (status) url = url + "?status=" + status;
    if (monthyear) url = url + "?holiday_date=" + monthyear;
    if (year) url = url + "?year=" + year;
    Axios.get(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          //toast.error(data.message || "Error");
          resolve([]);
        }
        resolve(data);
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};

export const listHostelHolidays = async (status = false, monthyear = false) => {
  return new Promise((resolve, reject) => {
    const d = new Date();
    let year = d.getFullYear();
    let url = `${ServiceUrl.HR.LIST_HOSTEL_HOLIDAYS}`;
    if (status) url = url + "?status=" + status;
    if (monthyear) url = url + "?holiday_date=" + monthyear;
    if (year) url = url + "?year=" + year;
    Axios.get(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          //toast.error(data.message || "Error");
          resolve([]);
        }
        resolve(data);
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};

export const listShift = async (status = false) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.HR.LIST_SHIFT}`;
    if (status) url = url + "?status=" + status;
    Axios.get(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          //toast.error(data.message || "Error");
          resolve([]);
        }
        resolve(data);
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};

export const listCommunity = async (status = false) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.HR.LIST_COMMUNITY}`;
    // if (status) url = url + "?status=" + status;
    Axios.get(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          //toast.error(data.message || "Error");
          resolve([]);
        }
        resolve(data);
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};

export const listEmployees = async (status = false, getValues = false) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.HR.LIST_EMPLOYEES}`;
    if (status) url = url + "?status=" + status;
    if (getValues) url = url + "&" + getValues;
    Axios.get(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          toast.error(data.message || "Error");
          resolve([]);
        }
        resolve(data.data);
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};

export const listSalaryEmployees = async (
  status = false,
  getValues = false
) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.PAYROLL.LIST_EMPLOYEES}`;
    if (status) url = url + "?status=" + status;
    if (getValues) url = url + "&" + getValues;
    Axios.get(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          toast.error(data.message || "Error");
          resolve([]);
        }
        resolve(data.data);
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};

export const listBranches = async (status = false, leaveDate = false) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.HR.LIST_BRANCHES}`;
    if (status) url = url + "?status=" + status;
    Axios.get(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          toast.error(data.message || "Error");
          resolve([]);
        }
        resolve(data.data);
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};
export const listsubject = async (status = false, leaveDate = false) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.SETTINGS.LIST_SUBJECT}`;
    if (status) url = url + "?status=" + status;
    Axios.get(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          toast.error(data.message || "Error");
          resolve([]);
        }
        resolve({ data: data.data, count: data.count });
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};

export const listsubjects = async (status = false, leaveDate = false) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.SETTINGS.LIST_SUBJECT}`;
    if (status) url = url + "?status=" + status;
    Axios.get(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          //toast.error(data.message || 'Error');
          resolve([]);
        }

        resolve(data.data);
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};

export const listCourseSubjects = async (status = false, leaveDate = false) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.SETTINGS.LIST_COURSE_SUBJECT}`;
    if (status) url = url + "?status=" + status;
    Axios.get(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          toast.error(data.message || "Error");
          resolve([]);
        }
        //resolve(data.data);
        resolve({ data: data.data, count: data.count });
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};

export const lisstafftsubject = async (status = false, getValues = false) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.SETTINGS.LIST_STAFFSUBJECT}`;
    if (status) url = url + "?status=" + status;
    if (getValues) url = url + "&" + getValues;
    Axios.get(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          toast.error(data.message || "Error");
          resolve([]);
        }
        resolve({ data: data.data, count: data.count });
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};

export const liststDepartments = async (status = false, leaveDate = false) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.SETTINGS.LIST_DEPARTMENT}`;
    if (status) url = url + "?status=" + status;
    Axios.get(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          //toast.error(data.message || 'Error');
          resolve([]);
        }
        resolve(data.data);
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};

export const listsTitle = async (status = false, leaveDate = false) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.FEEDBACKS.LIST_FEEDBACK_TITLE}`;
    if (status) url = url + "?status=" + status;
    Axios.get(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          //toast.error(data.message || 'Error');
        }
        resolve(data.data);
      })
      .catch(function (error) {
        //resolve([]);
        return [];
      });
  });
};

export const getLedgerList = async (status = false, getValues = false) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.FINANCE.LIST_LEDGERS}`;
    if (status) url = url + "?status=" + status;
    if (getValues) url = url + "&" + getValues;
    Axios.post(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          toast.error(data.message || "Error");
          resolve([]);
        }
        resolve(data.data);
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};

export const listDepartments = async (status = false, leaveDate = false) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.HR.LIST_DEPARTMENTS}`;
    if (status) url = url + "?status=" + status;
    Axios.get(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          toast.error(data.message || "Error");
          resolve([]);
        }
        resolve(data.data);
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};
export const listEmployeeType = async (status = false, leaveDate = false) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.HR.LIST_TYPE}`;
    if (status) url = url + "?status=" + status;
    Axios.get(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          //toast.error(data.message || "Error");
          resolve([]);
        }
        resolve(data.data);
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};

export const listEmployeeClassification = async (status = false) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.HR.LIST_CLASSIFICATION}`;
    if (status) url = url + "?status=" + status;
    Axios.get(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          //toast.error(data.message || "Error");
          resolve([]);
        }
        resolve(data.data);
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};

export const listEmployeeCategories = async (status = false) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.HR.LIST_CATEGORY_EMPLOYEE}`;
    if (status) url = url + "?status=" + status;
    Axios.get(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          //toast.error(data.message || "Error");
          resolve([]);
        }
        resolve(data.data);
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};

export const listDesignations = async (status = false, leaveDate = false) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.HR.LIST_DESIGNATIONS}`;
    if (status) url = url + "?status=" + status;
    Axios.get(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          //toast.error(data.message || "Error");
          resolve([]);
        }
        resolve(data.data);
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};

export const listIncharge = async (status = false, leaveDate = false) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.HR.LIST_INCHARGE}`;
    if (status) url = url + "?status=" + status;
    Axios.get(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          //toast.error(data.message || "Error");
          resolve([]);
        }
        resolve(data.data);
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};

export const listSection = async (status = false) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.HR.LIST_SECTION}`;
    if (status) url = url + "?status=" + status;
    Axios.get(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          //toast.error(data.message || "Error");
          resolve([]);
        }
        resolve(data.data);
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};

export const listTraining = async (status = false, leaveDate = false) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.SETTINGS.LIST_TRAINING}`;
    if (status) url = url + "?status=" + status;
    Axios.get(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          //toast.error(data.message || "Error");
          resolve([]);
        }
        resolve(data.data);
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};

export const listHostel = async (status = false, leaveDate = false) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.UTILITIES.LIST_HOSTEL}`;
    if (status) url = url + "?status=" + status;
    Axios.get(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          //toast.error(data.message || "Error");
          resolve([]);
        }
        resolve(data.data);
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};

export const listGradeSettings = async (gradeId) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.HR.LIST_GRADE_SETTINGS}`;
    if (gradeId) url = url + "?grade_id=" + gradeId;
    Axios.get(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          toast.error(data.message || "Error");
          resolve([]);
        }
        resolve(data.data);
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};

export const listLeaveType = async (status = false) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.HR.LIST_LEAVE}`;
    if (status) url = url + "?status=" + status;
    Axios.get(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          //toast.error(data.message || "Error");
          resolve([]);
        }
        resolve(data);
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};

export const listExperience = async (status = false) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.HR.V2.LIST_EXPERIENCE}`;
    if (status) url = url + "?status=" + status;
    Axios.get(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          //toast.error(data.message || "Error");
          resolve([]);
        }
        resolve(data);
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};

export const listQualification = async (status = false) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.HR.V2.LIST_QUALIFICATION}`;
    if (status) url = url + "?status=" + status;
    Axios.get(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          //toast.error(data.message || "Error");
          resolve([]);
        }
        resolve(data);
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};

export const listEmployeeLeave = async (status = false, empid = false) => {
  return new Promise((resolve, reject) => {
    let url = `${ServiceUrl.HR.EMPLOYEE_LEAVE_LIST}`;
    if (status) url = url + "?status=" + status + "&emp_id=" + empid;
    Axios.get(url)
      .then(function ({ data }) {
        if (data.status != "1") {
          //toast.error(data.message || 'Error');
          resolve([]);
        }
        resolve(data.data);
      })
      .catch(function (error) {
        //resolve([]);
        resolve([]);
      });
  });
};

export const _UpdateEmployeePersonal = async (searchData = false) => {
  let url = ServiceUrl.HR.V2.UPDATE_PERSONAL;
  return callApi(url, "post", searchData && searchData);
};

export const _UpdateEmployeeCommunication = async (searchData = false) => {
  let url = ServiceUrl.HR.V2.UPDATE_COMMUNICATION;
  return callApi(url, "post", searchData && searchData);
};

export const _UpdateEmployeeBank = async (searchData = false) => {
  let url = ServiceUrl.HR.V2.UPDATE_BANK;
  return callApi(url, "post", searchData && searchData);
};

export const _UpdateEmployeeQuali = async (searchData = false) => {
  let url = ServiceUrl.HR.V2.UPDATE_QUALI;
  return callApi(url, "post", searchData && searchData);
};

export const _UpdateEmployeeExperience = async (searchData = false) => {
  let url = ServiceUrl.HR.V2.UPDATE_EXPERIENCE;
  return callApi(url, "post", searchData && searchData);
};
export const _AddEmployeeExperience = async (searchData = false) => {
  let url = ServiceUrl.HR.V2.ADD_EXPERIENCE;
  return callApi(url, "post", searchData && searchData);
};
export const _AddEmployeeExamination = async (searchData = false) => {
  let url = ServiceUrl.HR.V2.ADD_EXAMINATION;
  return callApi(url, "post", searchData && searchData);
};
export const _UpdateEmployeeExamination = async (searchData = false) => {
  let url = ServiceUrl.HR.V2.UPDATE_EXAMINATION;
  return callApi(url, "post", searchData && searchData);
};
export const _UpdateEmployeeResearch = async (searchData = false) => {
  let url = ServiceUrl.HR.V2.UPDATE_RESEARCH;
  return callApi(url, "post", searchData && searchData);
};
export const _UpdateEmployeeThesis = async (searchData = false) => {
  let url = ServiceUrl.HR.V2.UPDATE_THESIS;
  return callApi(url, "post", searchData && searchData);
};
export const _UpdateEmployeeDoctoral = async (searchData = false) => {
  let url = ServiceUrl.HR.V2.UPDATE_DOCTORAL;
  return callApi(url, "post", searchData && searchData);
};

export const _NewEmployeeCode = async (searchData = false) => {
  let url = ServiceUrl.HR.EMPLOYEE_NEW_CODE;
  return callApi(url);
};

export const CountResearchExperience = async (searchData = false) => {
  let url = ServiceUrl.HR.V2.COUNT_RESEARCH_EXPERIENCE;
  return callApi(url);
};

export const CountCollegeExperience = async (searchData = false) => {
  let url = ServiceUrl.HR.V2.COUNT_RESEARCH_EXPERIENCE;
  return callApi(url);
};

export const DeleteExperience = async (searchData = false) => {
  let url = ServiceUrl.HR.V2.DELETE_EXPERIENCE;
  return callApi(url);
};

export const SaveQualification = async (searchData = false) => {
  let url = ServiceUrl.HR.V2.SAVE_QUALIFICATION;
  return callApi(url, "post", searchData && searchData);
};

export const UpdateQualification = async (searchData = false) => {
  let url = ServiceUrl.HR.V2.UPDATE_QUALIFICATION;
  return callApi(url, "post", searchData && searchData);
};

export const DeleteQualification = async (searchData = false) => {
  let url = ServiceUrl.HR.V2.DELETE_QUALIFICATION;
  return callApi(url);
};

export const _LIST_EMPLOYEES = async (searchData = false) => {
  let url = ServiceUrl.HR.LIST_EMPLOYEES;
  if (searchData) url += "?" + jsonToQuery(searchData);
  return callApi(url);
};

export const _LIST_HOLIDAYS = async (searchData = false) => {
  let url = ServiceUrl.HR.LIST_HOLIDAYS;
  if (searchData) url += "?" + jsonToQuery(searchData);
  return callApi(url);
};

//dashboard
export const _PRESENT_XP = async (searchData = false) => {
  let url = ServiceUrl.HR.EMP_INCHARGE.LIST_POSITION;
  return callApi(url, "post", searchData && searchData);
};
export const _PREV_XP = async (searchData = false) => {
  let url = ServiceUrl.HR.V2.LIST_EXPERIENCE;
  return callApi(url, "post", searchData && searchData);
};
export const _LIST_MEMO = async (searchData = false) => {
  let url = ServiceUrl.HR.EMP_INCHARGE.LIST_SUSPENSION;
  return callApi(url, "post", searchData && searchData);
};

export const _LIST_TRAINING = async (searchData = false) => {
  let url = ServiceUrl.HR.EMP_INCHARGE.LIST_FACULTY;
  return callApi(url, "post", searchData && searchData);
};
export const _LIST_PHD_RESEARCH = async (searchData = false) => {
  let url = ServiceUrl.HR.V2.LIST_RESEARCH;
  return callApi(url, "post", searchData && searchData);
};

export const _LIST_RESEARCH_GUIDANCE = async (searchData = false) => {
  let url = ServiceUrl.HR.EMP_INCHARGE.LIST_RESEARCH;
  return callApi(url, "post", searchData && searchData);
};

export const _LIST_RESEARCH_PROJECTS = async (searchData = false) => {
  let url = ServiceUrl.HR.EMP_INCHARGE.LIST_RESEARCH_PROJECTS;
  return callApi(url, "post", searchData && searchData);
};

export const _LIST_PUBLICATIONS = async (searchData = false) => {
  let url = ServiceUrl.HR.EMP_INCHARGE.LIST_PUBLICATION;
  return callApi(url, "post", searchData && searchData);
};

export const _LIST_CONFERENCE = async (searchData = false) => {
  let url = ServiceUrl.HR.EMP_INCHARGE.LIST_PRESENTATION;
  return callApi(url, "post", searchData && searchData);
};

export const _LIST_AWARDS = async (searchData = false) => {
  let url = ServiceUrl.HR.EMP_INCHARGE.LIST_AWARDS;
  return callApi(url, "post", searchData && searchData);
};

export const _TRANSFER_SECTION = async (searchData = false) => {
  let url = ServiceUrl.HR.EMP_INCHARGE.LIST_SECTION_TRANSFER;
  return callApi(url, "post", searchData && searchData);
};

export const _BulkSearchEstablishment = async (searchData = false) => {
  let url = ServiceUrl.HR.EMP_INCHARGE.BULK_SEARCH_ESTBLISHMENT;
  return callApi(url, "post", searchData && searchData);
};
